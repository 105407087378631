import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import planView from '../views/planView.vue'
import Pages from '../views/pages.vue'

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/plan/:id', name: 'plan', component: planView },
  // { path: '/pages/:id', name: 'pages', component: Pages },
  // redirect '/terms' to '/pages/65'
  { path: '/terms', component: Pages,  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
