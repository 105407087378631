<template>
  <main  :class="'site-' + site.name" :style="'--color1: ' + site.color1 + '; color2: ' + site.color2 + ';'" v-if="loaded">
    <!-- <input type="checkbox" @change="a"> -->
    <transition>
      <router-view :site="site" :plans="plans"></router-view>
    </transition>
  </main>
  <footer>
    
    <ul>
      <li><router-link to="/">ראשי</router-link></li>
      <li><router-link to="/terms">תנאי שימוש</router-link></li>
      <li><router-link to="/contact">יצירת קשר</router-link></li>
    </ul>
    <ul>
      <li>כל הזכויות שמורות לKVOD</li>
      <li><a href="tel:+972545852206">054-585-2206</a></li>
      <li>בר כוכבא 4, בני ברק</li>
    </ul>

  </footer>
</template>

<script>
export default {
  data() {
    return {
      plans: [],
      loaded: false,
    }
  },
  mounted() {
    this.api({ action: 'general'}, (data)=>{
      let words = {}
      if (this.site === 'kvod') {
        for (let i = 0; i < data.data.kvod_words.length; i++) {
          const element = data.data.kvod_words[i];
          words[element.sysName] = element.value
        }
      } else {
        for (let i = 0; i < data.data.wizzo_stream_words.length; i++) {
          const element = data.data.kvod_words[i];
          words[element.sysName] = element.value
        }
      }
      
      this.$store.words = words;
      this.plans = data.data.plans;
      this.loaded = true
    })
  },
  methods: {
    a() {
      if (this.site.name === 'kvod') {
          this.site = {
          name: 'wizzo-stream',
          display_name: 'wizzo stream',
          color1: '#2EF7BA',
          color2: '#2B2D42',
        }

      } else {
        this.site = {
          name: 'kvod',
          display_name: 'kvod',
          color1: '#d40040',
          color2: '#000b3c',
        }
      }
    },
  }
}
</script>

<style lang="scss">
main {

  &.site-kvod {
      .btn { color: #fff;}
      .logo {  background-image: url(./assets/kvod/logo_dark.png);}
      .head .left .logo{ background-image: url(./assets/kvod/logo.png);}
    }
    .logo {  background-image: url(./assets/logo_dark.png); background-position: center center; background-size: contain; background-repeat: no-repeat;
      @media (min-width: 600px) { width: 140px; height: 60px;}
      @media (max-width: 600px) {width: 90px; height: 40px;}
    }
    nav { z-index: 999; position: fixed; top: 0; left: 0; right: 0;box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); padding-block: 10px; background-color: #fff; display: flex; justify-content: space-between; align-items: center; color: #fff; opacity: 0; transform: translateY(-100%); transition: opacity 0.3s ease, transform 0.3s ease; 
      @media (min-width: 600px) { padding-inline:75px 30px;}
      @media (max-width: 600px) { padding-inline: var(--gap);}
      &.active {opacity: 1; transform: translateY(0);}
      .btns { display: flex; align-items: center; gap: var(--gap);
        .start {  }
        .login { color: #000; font-weight: 400; font-size: var(--h4); padding: 6px; }
      }
    }
    .btn { background: var(--color1); text-align: center; color: var(--color2); padding: var(--padding_s) var(--padding_n); font-size: var(--h4); border-radius: var(--radius_xs); font-weight: 700; width: fit-content; box-shadow: 0px 8px 8px 0px rgba(209, 239, 136, 0.25);} 
}

footer { display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 20px;
  background-color: var(--color2);
  color: var(--color1);
  padding: var(--padding_n);
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    li {
      font-size: var(--h4);
      a { color: var(--color1); text-decoration: none;}
    }
  }

  ul:nth-child(2) {
    opacity: 0.6;
  }
}
</style>
