<template>

    <div class="centered pages">
        <div v-if="item">
            <h1>{{ item.title }}</h1>
            <div v-html="item.text"></div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {
            item: false
        }
    },
    mounted() {

        this.api({ action: 'get_page', data: {id: 65}}, (data) => {
            
            this.item = data.data;
        });

    },
}

</script>

<style lang="scss" scoped>
.pages {

    
    border-radius: var(--radius_b);
    background: #fff;
    padding-top: 44px;
    padding-right: 76px;
    padding-bottom: 88px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6.6vw;

    h1 {
        font-size: var(--h1);
        font-weight: 700;
        margin-bottom: 25px;
    }
}
</style>