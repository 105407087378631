<template>
  <div id="plan_view" v-if="plan">
    <div class="centered">
        <div class="details_signup_container">
            <div class="details_container">
                <div class="title_price_container">
                    <h2 class="title" v-text="'תוכנית ' + plan.name"></h2>
                    <div class="price_con">
                        <div class="price_shekel">
                            <p class="price" v-text="plan.price"></p>
                            <span class="shekel">₪</span>
                        </div>
                        <div class="per" v-text="plan.text_ander_praice"></div>
                    </div>
                </div>
                <div class="includes">
                    <h4>כלול בתוכנית</h4>
                    <ul class="advantages_container">
                        <li class="advantage">
                            <span class="check"></span>
                            <p class="text" v-text="plan.text_field_a"></p>
                        </li>
                        <li class="advantage">
                            <span class="check"></span>
                            <p class="text" v-text="plan.text_field_b"></p>
                        </li>
                        <li class="advantage">
                            <span class="check"></span>
                            <p class="text" v-text="plan.text_field_c"></p>
                        </li>
                        <li class="advantage">
                            <span class="check"></span>
                            <p class="text" v-text="plan.text_field_d"></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="signup_container">
                <template v-if="step==1">
                    <h3 v-html="get_word('plan_form_title')"></h3>
                    <form @submit.prevent="handleSignup">
                        <ul>
                            <li>
                                <label>שם מלא / שם החברה</label>
                                <input type="text" name="name" v-model="signupForm.name">
                            </li>
                            <li>
                                <label>אימייל</label>
                                <input type="email" name="email" v-model="signupForm.email">
                            </li>
                            <li>
                                <label>טלפון</label>
                                <input type="tel" name="phone" v-model="signupForm.phone">
                            </li>
                            <li>
                                <label>דומיין</label>
                                <span class="desc">הכנס את השם של הדומיין שתרצה להשתמש בו (לדוגמא: elicohen)</span>
                                
                                <label class="subomain_input_container">
                                    <span class="subdomain">https://</span>
                                    <input type="text" name="subfolder" onkeydown="return /[a-z]/.test(event.key)"  required placeholder="דומיין ייחודי" v-model="signupForm.subfolder" @input="autoWidth($event); check_folder()" ref="subdomain">
                                    <span class="subdomain">.kvod.co.il</span>
                                    <div v-show="folderStatus == false" style="color: red;"><i class="far fa-times-circle"></i></div>
                                    <div v-show="folderStatus == true" style="color: green;"><i class="far fa-check-circle"></i></div>
                                </label>
                            </li>
                            <li>
                                <input type="submit" class="btn" value="המשך">
                            </li>
                        </ul>
                    </form>
                </template>
                
                <template v-if="step==2">
                    <Payment :payment_id="paymentID" @payment_success="handlePaymentSuccess" @fail_msg="failMsg" @fail="fail" @cancel="paymentID = 0;payment_url=false" />
                </template>
                
                <template v-if="step==3">
                    <div  class="thanks">
                        <h3>תודה רבה על ההרשמה</h3>
                        <h4>אנו מקימים את החשבון של כעת.</h4>
                        <p>
                            בדקות הקרובות יישלח אליך מייל עם פרטי החשבון והסיסמה שלך.
                        </p>
                    </div>
                </template>
            </div>
        </div>
    </div>

    
  </div>
</template>

<script>
import Payment from '@/components/Payment.vue'

export default {
    components: {Payment},
    props: ['site'],
    data() {
        return {
            step: 1,
            plan: null,
            folderStatus: null,
            signupForm: {
                name: '', 
                email: '', 
                phone: '', 
                plan_id: this.$route.params.id,
                subfolder: '',
            },
            paymentID: 0,
            userToken: null,
        }
    },
    mounted() {
        this.api({ action: 'plans/get', data: { id: this.$route.params.id}}, (data)=>{
            this.plan = data.data;
        })
    },
    methods: {
        handleSignup() {

            let check = true;
            if (!this.signupForm.name) {
                check = false;
                alert('אנא מלא את השם')
            }
            else if (!this.signupForm.email) {
                check = false;
                alert('אנא מלא את האימייל')
            }
            else if (!this.signupForm.phone) {
                check = false;
                alert('אנא מלא את הטלפון')
            }
            else if (!this.signupForm.subfolder) {
                check = false;
                alert('אנא מלא את הדומיין')
            }
            else if (!(/[a-z]/.test(this.signupForm.subfolder)))
            {
                check = false;
                alert('אנא הכנס רק אותיות באנגלית')
            }

            if (!check) return;

            this.api({action: 'signup/add', data: this.signupForm}, (data) => {
                if (data.data) {
                    this.paymentID = data.data.payment.id;
                    this.userToken = data.data.user_id;
                    this.step = 2;

                    // alert('נשלח בהצלחה')
                }
            });
        },
        autoWidth() {
            this.$refs.subdomain.style.maxWidth = (this.$refs.subdomain.value.length * 8) + 'px';
        },
        check_folder() {
            this.api({action: 'signup/check_folder', data: {subfolder: this.signupForm.subfolder}}, (data) => {
                this.folderStatus = data.data;
            });
        },

        handlePaymentSuccess() {
            this.step = 3;

            this.api({action: 'signup/finish', data: {token: this.userToken}}, (data) => {
                console.log(data);
            });
        },

    }
}
</script>

<style lang="scss">
#plan_view { background: #F6F7F8;
    .details_signup_container { min-height: 100vh; display: flex; gap: 40px;
        @media (min-width: 600px) { padding: 60px;}
        @media (max-width: 600px) {  flex-direction: column;  padding: 20px;}
        .details_container { flex: 4; 
            .title_price_container { display: flex; flex-direction: column; gap: 26px; padding-block: 20px; border-bottom: 1px #D4DADF solid;
                h2.title { font-size: var(--h1); }
                .price_con { display: flex; gap: 12px; align-items: baseline;
                    @media (min-width: 600px) {}
                    @media (max-width: 600px) { flex-direction: column;     align-items: self-start;}
                    .price_shekel {display: flex; align-items: flex-end; gap: 5px; height: fit-content;
                        .price { font-size: 110px; font-weight: 700;}
                        .shekel { font-size: 50px; margin-bottom: 21px; }
                    }
                    .per { font-size: var(--h3);}
                }
            }
            .includes {  border-bottom: 1px #D4DADF solid; padding-block: 20px; display: flex; flex-direction: column; gap: 26px;
                h4 { font-size: var(--h3);}
            }
        }
        .signup_container { flex: 5; display: flex; flex-direction: column; gap: var(--gap_big); box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); background: #fff; border-radius: 16px;
            @media (min-width: 600px) { padding: 60px;}
            @media (max-width: 600px) { padding: 30px;}
            h3 { font-size: var(--h2); margin: 0 auto;}
            ul { display: flex; flex-direction: column; gap: var(--gap);
                li { 
                    label { font-size: var(--h4); margin-bottom: 10px;}
                    .desc { font-size: var(--h5); color: #acadb2; margin-bottom: 10px; display: block;}
                    input { height: 60px; padding: 0 10px; border: 1px solid #acadb2; border-radius: 8px; font-size: 16px; }


                                        

                    .subomain_input_container { direction: ltr; text-align: left; border: 1px solid #acadb2; border-radius: var(--radius_xs); background: #fff; display: flex; gap: 6px; align-items: center; padding: 0 15px;
                        input { max-width:0px; min-width: 70px; height: 60px; padding: 0 10px; border: none !important; border-radius: 8px; font-size: 16px; padding: 0px !important; }
                        .subdomain { font-size: 16px; }
                        span { opacity: 0.3;}
                    }

                }
            }
            .thanks {     font-size: var(--h2); margin-top: 116px; margin-inline: auto; text-align: center;}

        }
    }
}
</style>