<template>
    <div class="wzselect" :id="elID" :class="'theme-' + wzsettings.theme + ' ' + wzsettings.customClass" >
        <!-- <select class="wzselect-select">
            <option v-for="opt in optionsComp" :key="opt.key" :value="opt.key" :selected="opt.key==modelValue">{{opt.val}}</option>
        </select> -->

        <input class="wzselect-select" :value="modelValue">
        <div :style="'display: inline-block; width:' + wzsettings.width + '; position:relative;--wz-input-height:' + wzsettings.height" v-click-away="away">
        <!-- <div :style="'display: inline-block; width:' + wzsettings.width + '; position:relative;'" v-click-away="away"> -->
            <div class="wzselect-inp inp" type="text" @click="toggleOpen" :class="{open: isOpen, open_to_top: openDirection=='top', error: error}">
                <span v-html="valueComp"></span>
                <i v-show="!isOpen" class="fi-rr-angle-small-down"></i>
                <i v-show="isOpen" class="fi-rr-angle-small-up"></i>
            </div>
            <div class="wzselect-options" :class="{'open_to_top': openDirection=='top'}" v-if="isOpen" :style="optionsStyle">
                <div v-if="wzsettings.search" class="search">
                    <input type="text" v-model="q" @input="search" placeholder="Search" ref="inp">
                </div>
                <ul>
                    <li v-for="opt in optionsComp" :key="opt.key" :class="{selected: opt.key == modelValue}" v-html="opt.val" @click="val(opt)"></li>
                    <slot name="footer" />
                    <li v-if="optionsComp.length == 0">No Results</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { directive } from "vue3-click-away";
export default {
  directives: {
    ClickAway: directive
  },
  props:["settings", "options", "modelValue",'error'],
  emits: ['update:modelValue', 'change'],
    data(){
        return {
            elID: 'wzselect_' + this.uuid(),
            openDirection: 'bottom',
            openMaxHeight: 300,
            wzsettings: {
                theme: 'dark',
                placeholder: ``,
                label: ``,
                width: 'auto',
                height: '50px',
                search: false,
                customClass: '',
                url: '' // 'wizzocms/Admins/ac_search'
            },
            q: '',
            m_options: this.options,
            isOpen:false
        };
    },
    mounted(){

        for (let i in this.settings)
        {
            this.wzsettings[i] = this.settings[i];
        }

        /*
        for (let i in this.m_options)
        {
            if (this.m_options[i].key == this.value)
            {
                this.val(this.m_options[i]);
            }
        }*/
    },
    methods:{
        away: function() { 
        this.isOpen = false;
        },
        val(opt){ 
            //this.value = opt;
            this.isOpen = false;
            
            this.$emit('update:modelValue', opt.key);
            this.$emit('change', opt.key);

            this.q='';
        },
        toggleOpen(){
            this.isOpen=!this.isOpen;
            //let parent = this.getScrollParent(document.querySelector('#' + this.elID));

            if (this.isOpen)
            {
                if (this.wzsettings.search && !this.is_mobile())
                {
                    setTimeout(() => {
                        this.$refs.inp.focus();
                    }, 50);
                }

                this.calcOptionsSize();

                //parent.addEventListener('scroll', this.calcOptionsSize, true);
            }
            else
            {
                //parent.removeEventListener('scroll', this.calcOptionsSize, true);
            }
        }, 

        calcOptionsSize(){
            let parent = this.getScrollParent(document.querySelector('#' + this.elID));

            const parentPos = parent.getBoundingClientRect();
            const childPos  = document.querySelector('#' + this.elID).getBoundingClientRect();
            const relativePos = {};

            relativePos.top    = childPos.top - parentPos.top,
            relativePos.right  = childPos.right - parentPos.right,
            relativePos.bottom = childPos.bottom - parentPos.bottom,
            relativePos.left   = childPos.left - parentPos.left;

            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
             var viewportOffset = document.querySelector('#' + this.elID).getBoundingClientRect();
            var top = viewportOffset.top; // relativePos.top;
            // console.log(top)
            if (vh/2 < top) 
            {
                this.openDirection = 'top';
                this.openMaxHeight = (viewportOffset.top < top ? viewportOffset.top : top) - 100;
            }
            else
            {
                this.openDirection = 'bottom';
                this.openMaxHeight = (viewportOffset.top < vh-top ? vh-viewportOffset.top : vh-top) - 100;
            }

            //alert(this.openMaxHeight)
        },

        getScrollParent(node=null) {
            if (node == null) return document.querySelector('body');

            if (node.scrollHeight > node.clientHeight) 
            {
                return node;
            } 
            else 
            {
                return this.getScrollParent(node.parentNode);
            }
        },

        search(){
            if (this.wzsettings.url != '')
            {
                fetch(this.wzsettings.url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ q: this.q})

                    
                }).then(res => res.json())
                .then((res) => {
                    this.m_options = res;
                });
            }

        },
            
        uuid() {
            return Math.random().toString(36).slice(4)
        },
    },
    computed:{
        
        optionsStyle(){ 
            return {
                maxHeight: this.openMaxHeight+ 'px'
            }
        },
        valueComp(){
            let lbl = (this.wzsettings.label != '' ? this.wzsettings.label + ': ' : '');
            
            if (!this.isNull(this.modelValue))
            {
                for (let i in this.m_options)
                {
                    if (this.modelValue == this.m_options[i].key) return lbl + (lbl != '' ? '<b>' + this.m_options[i].val + '</b>' : this.m_options[i].val);
                }
            }   
    
            return lbl + (lbl != '' ? '<b>' + this.wzsettings.placeholder + '</b>' : this.wzsettings.placeholder);
        },
        optionsComp()
        {
            let ret = [];
            
            // console.log(this.m_options)

            let findIn = function(str, q) { 
                if (str == undefined) return false;
                return str.toUpperCase().indexOf(q.toUpperCase()) >= 0; };
            
            for (let i in this.m_options)
            {
                if (!this.search || findIn(this.m_options[i].val,this.q)) ret.push(this.m_options[i]);
            }
            
            return ret;
        }
    },

    watch:{
        options(){
            this.m_options = this.options;
        }
    }
}

</script>

<style lang="scss" scoped>
.wzselect{ display: inline; 

    &.theme-light {
        --wz-input-bg: #fff;
        --wz-text-color: #303756;
        --wz-bg-color: #fff;
        // --wz-input-height: 50px;
    }
    &.theme-dark {
        --wz-input-bg: var(--bg_color);
        --wz-text-color: #303756;
        --wz-bg-color: #fff;
        // --wz-input-height: 50px;
    }
    &.theme-transparent {
        --wz-input-bg: ;
        --wz-text-color: #303756;
        --wz-bg-color: #fff;
    }

    color: var(--wz-text-color);
    .wzselect-select { display: none !important; }
    .wzselect-inp { 
        display: flex;box-shadow: 0px 4px 10px rgba(53, 78, 104, 0.1);
        &.error {
            &:not([type=checkbox]) {
            box-shadow: 0px 0px 4px var(--alert);
            &:focus { box-shadow: 0px 0px 0px 4px #B4231840;}
            }
        }
        &.open {
            border-radius: var(--radius) var(--radius) 0 0;
            &.open_to_top {border-radius: 0 0 var(--radius) var(--radius);}
        }
        cursor: pointer; background: var(--wz-input-bg); padding: 0 15px; border-radius: var(--radius); white-space: nowrap; min-height: var(--wz-input-height); position: relative; display: flex; justify-content: space-between; align-items: center; line-height: normal !important; 
        span { display: flex; gap:6px; align-items: center; width: 100%;font-size: 18px;}
        i { content: ''; width:18px; height: 18px; display: block; font-size: 18px; margin-right: 6px; }
    }
    
    .wzselect-options { position: absolute;  right: 0px; min-width: 100%; background: var(--wz-bg-color);  z-index: 999999; overflow-y: auto; 
        ul { list-style: none; display: block; padding: 5px;
            li { min-height: 40px; display: flex; align-items: center;border-radius: 5px; padding: 0 10px; white-space: nowrap; font-size: 18px; cursor: pointer;

                @media (max-width: 600px) {gap:var(--gap);}
                @media (min-width: 600px) {gap:6px;}

                &:hover { background: #F5F6F8; color:#333 }
                &.selected { /*background: #eee; color:#333*/ font-weight: bold; }
            }
        }
        &:not(.open_to_top) {  top: calc(100% + 0px); border-radius: 0 0 12px 12px;box-shadow: 1px 9px 10px rgba(53, 78, 104, 0.1);;}
        &.open_to_top { bottom: calc(100% + 0px);border-radius: 12px 12px 0 0 ;box-shadow: -1px -9px 10px rgba(53, 78, 104, 0.1);}
    }
    .search { background: #eee; position: sticky; top: 0px;
        input { width:100%; border:none; height: 30px; padding: 0 10px; font-size: 18px; background: none;}
    }
}
</style>