<template>
    <div id="payment_wrapper" v-if="loaded">
        <div class="title">{{get_word('payment_step')}}</div>
        <div class="divider"></div>
        <!-- <div id="payment_details" class="title" v-if="paymentData && paymentData.price != ''">
            Payment
            <div class="name" v-if="paymentData && paymentData.name != ''" v-html="paymentData.name"></div>
            <div class="price_wrapper">
                <div class="price" v-if="paymentData && paymentData.price != ''">
                    <span>מחיר:</span>
                    <span>{{ paymentData.price }}</span>
                </div>
            </div>        
        </div>
        <div class="divider"></div> -->
        <div id="payment_form" :class="{show: loaded }">
            <form ref="myForm" role="form" id="checkout-form" >
                 <div class="form-group" id="card-number-group">
                    <label for="card-number-container" class="control-label">מספר הכרטיס:</label>
                    <div class="input-group input-group-lg">
                        <div id="card-number-container" :class="'form-control payment_input '+cardClass"></div>
                        <span class="input-group-addon"></span>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group" id="social-id-group">
                        <label for="social-id-container" class="control-label">מספר תעודת זהות:</label>
                        <input type="text" v-model="socialId" :class="'form-control payment_input '">
                    </div>

                    <div class="form-group" id="card-expiration-group">
                        <label for="card-expiration-container" class="control-label">תוקף הכרטיס:</label>
                        <div id="card-expiration-container" :class="'form-control payment_input '+expClass"></div>
                        <p id="card-expiration-messages" class="help-block" style="display: none;"></p>
                    </div>
                    <div class="form-group" id="card-cvv-group">
                        <label for="card-cvv-container" class="control-label">CVV:</label>
                        <div id="card-cvv-container" :class="'form-control payment_input '+cvvClass"></div>
                        <p id="card-cvv-messages" class="help-block" style="display: none;"></p>
                    </div>
                    
                    
                    <div class="form-group" id="card-expiration-group" v-if="installments > 1">

                        <label for="card-expiration-container" class="control-label">{{get_word('installments')}}:</label>
                        <wzselect v-model="installments" :settings="{ theme: 'light', customClass: 'choose_template_select', placeholder: get_word('installments'), width: '100%' }" :options="[1, 2, 3].map((num)=> { return { key: num, val: num.toString() } })" />
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="form-group" id="first-name-group">
                        <label for="first-name-container" class="control-label">שם בעל הכרטיס:</label>
                        <div id="first-name-container" :class="'form-control payment_input '+nameClass"></div>
                        <p id="first-name-messages" class="help-block" style="display: none;"></p>
                    </div>
                    
                </div> -->
               
              
                <!-- <label class="checkbox_wrap">
                    <div class="checkbox">
                    <label class="container_check">
                        <input type="checkbox" v-model="saveToken" />
                        <span class="checkmark"></span>
                    </label>
                    <label id="check_box" class="check_text">{{ get_word('save_token') }}</label>
                    </div>
                </label> -->
                <div class="row_bottom">
                    <!-- <div class="icons">
                        <div class="icon_1 icon"/>
                        <div class="icon_2 icon"/>
                        <div class="icon_3 icon"/>
                    </div> -->
                    
                    <div class="total" v-if="paymentData && paymentData.price != ''">
                        <div class="title">לתשלום: <span>{{ parseFloat(paymentData.price) }}₪</span></div>
                    </div>

                    <div class="payment_btn_wrapper">
                        
                        <!-- <a  @click="$emit('cancel')" style="color: #333; text-decoration: underline;">ביטול</a> -->
                        <button class="subscribe btn dom submit btn-lg btn-block" id="submit-button" :disabled="!isLoading && !allow_to_pay">
                            לתשלום
                            <div id="loading" v-if="isLoading" ></div>
                        </button>
                    </div>
                </div>

                <div class="row" style="display:none;" id="checkout-form-errors">
                    <p class="payment-errors"></p>
                </div>
		</form>
	</div>
    <pre id="console-pre"></pre>
    </div>
</template>

<script>
import wzselect from '@/components/wzselect.vue'

export default {
    components:{wzselect},  
    props:["payment_id"],
    data() {
        return {
            apiKey: '802c7791-0954-47bb-a8c2-f2c541ae90e0',
            paymentData: {id: '', price: '', first_name: '', last_name: '', email: '', phone: ''},
            orderData:[],
            installments: 1,
            nameClass:'',
            socialId:'',
            expClass:'',
            cvvClass:'',
            cardClass:'',
            socialClass: '',
            saveToken:false,
            isLoading:false,
            allow_to_pay:false,
            loaded:false,
            allFieldsReady:[],
            number:false,
            exp:false,
            name:false,
            social:false,
            cvv:false,
            cardSettings : {
                placeholder: '0000-0000-0000-0000',
                messages: { invalid: this.get_word('invalid_card_num'),required:this.get_word('required') },
                styles: {
                    base: {
                        '::placeholder': {
                            'text-align': 'right'
                        },
                        'text-align': 'right'
                    },
                    valid: {
                        'color': '#354e68'
                    }
                }
            },
            expSettings : {
                placeholder: 'MM/YY',
                messages: { invalid: this.get_word('invalid_exp_data'),required:this.get_word('required') },
                styles: {
                    base: {
                        '::placeholder': {
                            'text-align': 'right'
                        },
                        'text-align': 'right'
                    },
                    valid: {
                        'color': '#354e68'
                    }
                }
            },
            cvvSettings : {
                placeholder: 'CVV',
                messages: { invalid: this.get_word('invalid_cvv'),required:this.get_word('required') },
                styles: {
                    base: {
                        '::placeholder': {
                            'text-align': 'right'
                        },
                        'text-align': 'right'
                    },
                    valid: {
                        'color': '#354e68'
                    }
                }
            },
            nameSettings : {
                placeholder: ' ',
                messages: { invalid: this.get_word('invalid_name'),required:this.get_word('required') },
                styles: {
                    base: {
                        '::placeholder': {
                            'text-align': 'right'
                        },
                        'text-align': 'right'
                    },
                    valid: {
                        'color': '#354e68'
                    }
                }
            },
            socialSettings : {
                placeholder: ' ',
                messages: { invalid: this.get_word('invalid_id_card'),required:this.get_word('required') },
                styles: {
                    base: {
                        '::placeholder': {
                            'text-align': 'right'
                        },
                        'text-align': 'right'
                    },
                    valid: {
                        'color': '#354e68'
                    }
                }
            },
        }
    },

    
mounted() {

    this.api({ action: 'signup/get_payment', data: {token: this.payment_id}}, (data) => {
        console.log(data)
        this.paymentData.id = data.data.payment.id;
        this.paymentData.price = data.data.payment.amount;

        // seperating the name to first and last name
        this.paymentData.first_name = data.data.user.display_name.split(' ')[0]
        this.paymentData.last_name = data.data.user.display_name.split(' ')[1]
        this.paymentData.email = data.data.user.username;
        this.paymentData.phone = data.data.user.phone;

        this.loaded = true;

        this.init();
    })

  },
  methods:{

    init(){

            
        this.socialId = this.id_card;
        const allFieldsReady = [];
        // Initialize PayMe hosted fields
        PayMe.create(this.apiKey, { testMode: false }).then((instance) => {
        const fields = instance.hostedFields();
        const cardNumber = fields.create(PayMe.fields.NUMBER, this.cardSettings);

        allFieldsReady.push(
            cardNumber.mount('#card-number-container')
        );
        cardNumber.on('keyup', state => this.toggleValidationMessages('card',state));
        cardNumber.on('validity-changed', state => this.toggleValidation('card',state));
        const cardExpiration = fields.create(PayMe.fields.EXPIRATION,this.expSettings);
        
        allFieldsReady.push(
            cardExpiration.mount('#card-expiration-container')
        );
        cardExpiration.on('keyup', state => this.toggleValidationMessages('exp',state));
        cardExpiration.on('validity-changed', state => this.toggleValidation('exp',state));

        const cardCvv = fields.create(PayMe.fields.CVC, this.cvvSettings);
        allFieldsReady.push(
            cardCvv.mount('#card-cvv-container')
        );
        
        cardCvv.on('keyup', state => this.toggleValidationMessages('cvv',state));
        cardCvv.on('validity-changed', state => this.toggleValidation('cvv',state));

        // const social_id = fields.create(PayMe.fields.SOCIAL_ID,this.socialSettings);
        // allFieldsReady.push(
        //     social_id.mount('#social-id-container')
        // );
        // social_id.on('validity-changed', state => this.toggleValidation('social',state));
        //   social_id.on('keyup', state => this.toggleValidationMessages('social',state));


        // const firstName = fields.create(PayMe.fields.NAME_FIRST,this.nameSettings);
        // allFieldsReady.push(
        //     firstName.mount('#first-name-container')
        // );
    
        // firstName.on('keyup', state => this.toggleValidationMessages('name',state));
        // firstName.on('validity-changed', state => this.toggleValidation('name',state));

        

    
        

        // Add event listeners or further customization here
        const formElement = this.$refs.myForm;
        Promise.all(allFieldsReady).then(() => this.allow_to_pay = true);

        formElement.addEventListener('submit', ev => {
            ev.preventDefault();
            if(!this.number ||  !this.cvv || !this.exp || this.isLoading || !this.allow_to_pay) {
                alert('Please fill in all fields');
                return false;
                }
            
            // const userData = this.getDataJSON();


            const sale = {
                payerFirstName: (this.paymentData.first_name ?? 'כללי'),
                payerLastName: (this.paymentData.last_name ?? 'כללי'),
                payerEmail: (this.paymentData.email ?? 'support@tech1.co.il'),
                payerPhone: (this.paymentData.phone ?? '0523339333'),
                payerSocialId: this.socialId,
                total: {
                    label: this.paymentData.id,
                    amount: {
                        currency: 'ILS',
                        value: (Number(parseFloat(this.paymentData.price)) > 0 ? String(parseFloat(this.paymentData.price)) : '5'),
                    }
                }
            };
            // console.log(sale);
            this.isLoading = true;

        instance.tokenize(sale)
        .then(postData => {
                this.api({ action: "signup/payment_save_token", method: "post", data: { postData: {...postData, payment_id: this.paymentData.id, installments: this.installments},saveToken:this.saveToken } }, (chargeData) => {
                if(chargeData.data.status)
                {
                    this.$emit("payment_success", chargeData.data.order_id);
                }
                else{
                    this.$emit("fail_msg");
                }
            });
        }).catch(err => {
                this.isLoading = false;
                console.log(err);
                this.alert('לא ניתן לבצע את התשלום');  
                // this.$emit("fail");
                return true;
                
        });

        });
        }).catch(err => {
            this.isLoading = false;
            console.log(err);
            this.alert('לא ניתן לבצע את התשלום');  
            // this.$emit("fail");
            return true;
        });

    },

    tokenizationStarted()
    {
        // submitButton.disabled = true;
    },
    tokenizationFinished()
    {
        // submitButton.disabled = false;
    },
   
    toggleValidationMessages(name, validationState)
    {

        switch (name) {
            case 'name':{
                if(validationState.isValid){
                    this.nameClass = 'valid';
                }
                else if(!validationState.isEmpty && !validationState.isValid)
                {
                    this.nameClass = 'invalid';
                }
                else{
                    this.nameClass = '';
                }
                break;
            }
            case 'exp':{
                if(validationState.isValid){
                    this.expClass = 'valid';
                }
                else if(!validationState.isEmpty && !validationState.isValid)
                {
                    this.expClass = 'invalid';
                }
                else{
                    this.expClass = '';
                }
                break;
            }
            case 'card':{
                if(validationState.isValid){
                    this.cardClass = 'valid';
                }
                else if(!validationState.isEmpty && !validationState.isValid)
                {
                    this.cardClass = 'invalid';
                }
                else{
                    this.cardClass = '';
                }
                break;
            }
            case 'cvv':{
                if(validationState.isValid){
                    this.cvvClass = 'valid';
                }
                else if(!validationState.isEmpty && !validationState.isValid)
                {
                    this.cvvClass = 'invalid';
                }
                else{
                    this.cvvClass = '';
                }
                break;
            }
            case 'social':{
                if(validationState.isValid){
                    this.socialClass = 'valid';
                }
                else if(!validationState.isEmpty && !validationState.isValid)
                {
                    this.socialClass = 'invalid';
                }
                else{
                    this.socialClass = '';
                }
                break;
            }
        }
    },
    toggleValidation(name, validationState)
    {
        // console.log(name);
        switch (name) {
            case 'name':{
                if(validationState.isValid){
                    this.name = true;
                }
                else{
                    this.name = true;
                }
                break;
            }
            case 'exp':{
                if(validationState.isValid){
                    this.exp = true;
                }
                else{
                    this.exp = false;
                }
                break;
            }
            case 'cvv':{
                if(validationState.isValid){
                    this.cvv = true;
                }
                else{
                    this.cvv = false;
                }
                break;
            }
            case 'card':{
                if(validationState.isValid){
                    this.number = true;
                }
                else{
                    this.number = false;
                }
                break;
            }
            case 'social':{
                if(validationState.isValid){
                    this.social = true;
                }
                else{
                    this.social = false;
                }
                break;
            }
        }
    }
},
}
</script>
<style scoped lang="scss">
.secondary{
        &{flex-direction: column;gap: 10px;}
        input{width: 100%;margin-bottom: 10px;}
    }
#payment_wrapper{display: flex;flex-direction: column;justify-content: center;align-items: center;gap: var(--gap_mbl);text-align: center;background-color: var(--bg_color);padding: 0px;width: 100%;}
.update_title{margin-bottom: 10px;}
#payment_form{
    
    &{display: flex; flex-direction: column; align-content: center; align-items: center;color: #fff;padding: 2px ;}
    @media (min-width: 600px) {width: 80%;}
    @media (max-width: 600px) {width: 90%;}
    
    form{width: 100%;display: flex;flex-direction: column;gap:var(--gap_mbl);}
    label,p{color:#0a1130;text-align: right;display: block;font-size: 18px;margin-bottom: 5px;display: block;}
    p{margin: 0 !important;}
    .payment_btn_wrapper{

            .btn{height: 40px; font-size: 24px;
                @media (min-width: 600px) {}
                @media (max-width: 600px) {width: 50vw;max-width: 150px;}
            }   
    }
    .row{ display: grid;grid-template-columns: repeat(2, 1fr);gap: 20px;justify-content: space-between;/* margin: 15px auto; */
        @media (min-width: 600px) { 
            .form-group{ width: 100%}
        }
        @media (max-width: 600px) {flex-direction:column;}
       
    }
     .input-lg{
          &{height: 35px;border-radius: 8px;min-width: 260px;}
          @media (min-width: 600px) {background-color: #fff;padding: 5px 10px;}
          @media (max-width: 600px) {border: 1px solid #D4DADA !important;padding: 5px;background: #F5F5F6;}
        }
    // .btn{margin: 20px auto;}
}
.loading_input{background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.3));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    -webkit-animation: shine 3s ease infinite;animation: shine 3s ease infinite;}
    @keyframes shine {
	to {
		// Move shine from left to right, with offset on the right based on the width of the shine - see background-size
		background-position: right -40px top 0;
	}
}
#loading {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }

  #payment_details{
    &{display: flex;align-items: center; justify-content: center;text-align: right; /*padding: 20px 0;   border-bottom: 1px #C5C7D0 solid; */}
    .name{    font-weight: 700;font-size: 22px; padding: 0 15px;}
    .price_wrapper{
        &{ display: flex;gap: 5px;flex-direction: column;align-items: flex-start;min-width: 110px;}
        .price{ display: flex;justify-content: space-between;width: 100%;font-weight: 500;align-items: center;
            span:first-of-type{font-weight: 700; font-size: 20px;}
        }
    }
  }
  .dets{background: #D7EBF9;padding: 10px 20px;color: #0a1130;font-size: 18px;border-radius: 12px;margin: 25px 0;}
  .row_bottom{display: flex;align-items: center;justify-content: space-between;margin-top: var(--gap_big);

    @media (min-width: 600px) {gap: 20px;}
    @media (max-width: 600px) {gap: 10px;flex-direction: column;}


    .total{color:#0a1130;
        .title{font-size: 28px;font-weight: bold;
            @media (max-width: 600px) {}
            @media (min-width: 600px) {}
        }
        span{font-weight: 400;}
    }
    // .icons{display: flex;gap: 10px;

    //     @media (max-width: 600px) {}
    //     @media (min-width: 600px) {margin-left: auto;}

    //     .icon{background-repeat: no-repeat;background-size:contain;background-position:center;height: 35px;width: 50px;}
    //     .icon_1{background-image: url(../assets/icon_1.png);}
    //     .icon_2{background-image: url(../assets/icon_2.svg);}
    //     .icon_3{background-image: url(../assets/icon_3.svg);}
    // }

    .first_line { display: flex; background: var(--bg_color); padding: 8px 15px; justify-content: space-between;align-items: center;
            @media (min-width: 600px) {gap: 10px;}
            @media (max-width: 600px) {flex-direction: column;gap:var(--gap_mbl);}
    }

.btn{gap: 8px !important;}
  }
  
.payment_input{width:100%; height: 50px; background: #fff; border:none; border-radius: 12px;padding: 0 15px;font-size: 20px;outline: none;color: #0a1130;position: relative;}
.payment_input.invalid{border: 1px solid #D83F3F;}
// .payment_input.invalid::after{background-image: url(../assets/error.svg);content: '';height: 24px;width: 24px;background-size: contain;background-repeat: no-repeat;position: absolute;left: 10px;top: 12px;}
.payment_input.valid{border: 1px solid #73C7AD;}
// .payment_input.valid::after{background-image: url(../assets/checkmark.svg);content: '';height: 24px;width: 24px;background-size: contain;background-repeat: no-repeat;position: absolute;left: 10px;top: 12px;}
.check_text {font-size: 16px !important;line-height: 1.4;}
</style>